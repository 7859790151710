export enum WillCountry {
    australia = 'Australia',
    britishVirginIslands = 'BritishVirginIslands',
    canada = 'Canada',
    caymanIslands = 'CaymanIslands',
    france = 'France',
    germany = 'Germany',
    gibraltar = 'Gibraltar',
    guernsey = 'Guernsey',
    hongKong = 'HongKong',
    india = 'India',
    ireland = 'Ireland',
    isleOfMan = 'IsleOfMan',
    israel = 'Israel',
    italy = 'Italy',
    japan = 'Japan',
    jersey = 'Jersey',
    luxembourg = 'Luxembourg',
    malaysia = 'Malaysia',
    // mainlandChina = "Mainland China",
    newZealand = 'NewZealand',
    philippines = 'Philippines',
    singapore = 'Singapore',
    southAfrica = 'SouthAfrica',
    // sweden = 'Sweden',
    switzerland = 'Switzerland',
    taiwan = 'Taiwan',
    thailand = 'Thailand',
    unitedKingdom = 'UnitedKingdom',
    unitedStates = 'UnitedStatesOfAmerica',
    vietnam = 'Vietnam',
}
