import { Script } from 'gatsby';
import React from 'react';
import EnvironmentUtils from '../../utils/environment.utils';

const GoogleScripts: React.FC = () => {
    return EnvironmentUtils.isProd ? (
        <>
            <Script id="gtag-script" src={`https://www.googletagmanager.com/gtag/js?id='G-Q1582M3YCE'`} />
            <Script id="gtag-init">{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-Q1582M3YCE',{
                    page_path: window.location.pathname,
                });
                gtag('config', 'AW-347601036');
            `}</Script>
        </>
    ) : (
        <></>
    );
};

export default GoogleScripts;
