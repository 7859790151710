import { WillCountry } from '../../../../data/will-countries/will-countries';

export default {
    premium: {
        titleHK: 'Hong Kong Premium Wills',
        titleSG: 'Singapore Premium Wills',
        titleMY: 'Malaysia Premium Wills',
        titleUS: 'Premium Wills',
        descriptionTag:
            'Personalized estate planning service from {{currencySymbol}}{{singlePrice}} for a single Will and {{currencySymbol}}{{couplePrice}} for couple Wills with unlimited updates. Premium Will writing service.',
        keywordsHK: 'Hong Kong premium Will service, Cross border estate planning',
        keywordsSG: 'Singapore premium Will service, Cross border estate planning',
        keywordsMY: 'Malaysia premium Will service, Cross border estate planning',
        keywordsUS: 'Personalized Will service, Cross border estate planning, Cross border Wills, international wills',
        premiumWills: 'Premium Personalized Will Service',
        premiumWills2: 'Premium Personalized Will',
        heading: 'Premium',
        heading2: 'Wills',
        subHeading: 'Whether you have assets in multiple countries, or want a fully customized estate plan, our premium product provides the whole package.',
        chatNow: 'Chat Now',
        productSub: '{{price}} for couples',
        includes1: 'Last Will and Testament',
        includes2: 'Appointment of permanent and temporary guardians',
        includes3: 'Letter to your Executors (To let them know that you have chosen them)',
        includes4: 'Letter of Wishes',
        includes5: 'List of Assets and Digital Assets',
        includes6: '1 Estate Planning Advice Meeting',
        includes7: 'Professional print of all documents',
        includes8: 'Signing and Witnessing of all documents',
        descriptionHeading: 'Is our premium service for you?',
        description:
            'If the online Will service is not suitable for you, or you would like a tailor-made will to cover your unique situation, then you should consider choosing our premium service.',
        description2: 'We have extensive cross border estate planning experience and can ensure that different situations are covered personally for you.',
        description3: 'We can write Wills for the following jurisdictions:',
        description4:
            '** This list is not exhaustive. Simply let us know what countries you you require and we will try our best to assist you. Please note that all Premium Wills are written in the English language only. **',
        how: 'How does it work?',
        initial: 'Initial Meeting',
        initialBody:
            'The process will start with a meeting with one of our Will specialists where he/she will gather information, take your Will instructions, and provide you with appropriate advice.',
        drafting: 'Document Drafting',
        draftingBody: 'Over the next two days, our team will draft the required documents. You will then have the opportunity to review them before finalization.',
        sign: 'Sign and Witness',
        signBody: 'Once the documents are finalized, a final meeting will take place to sign and witness the documents.',
        [WillCountry.unitedKingdom]: 'United Kingdom',
        [WillCountry.australia]: 'Australia',
        [WillCountry.unitedStates]: 'United States of America',
        [WillCountry.singapore]: 'Singapore',
        [WillCountry.malaysia]: 'Malaysia',
        [WillCountry.india]: 'India',
        [WillCountry.caymanIslands]: 'Cayman Islands',
        [WillCountry.jersey]: 'Jersey',
        [WillCountry.guernsey]: 'Guernsey',
        [WillCountry.isleOfMan]: 'Isle of Man',
        [WillCountry.thailand]: 'Thailand',
        [WillCountry.vietnam]: 'Vietnam',
        [WillCountry.philippines]: 'Philippines',
        // [WillCountry.mainlandChina]: 'Mainland China',
        [WillCountry.japan]: 'Japan',
        [WillCountry.hongKong]: 'Hong Kong',
        [WillCountry.canada]: 'Canada',
        [WillCountry.newZealand]: 'New Zealand',
        [WillCountry.taiwan]: 'Taiwan',
        [WillCountry.britishVirginIslands]: 'British Virgin Islands',
        [WillCountry.france]: 'France',
        [WillCountry.germany]: 'Germany',
        [WillCountry.italy]: 'Italy',
        [WillCountry.gibraltar]: 'Gibraltar',
        [WillCountry.luxembourg]: 'Luxembourg',
        [WillCountry.ireland]: 'Ireland',
        [WillCountry.israel]: 'Israel',
        // [WillCountry.sweden]: 'Sweden',
        [WillCountry.switzerland]: 'Switzerland',
        [WillCountry.southAfrica]: 'South Africa',
    },
};
